import React from "react";
import jsonData from './data/data.json';
class Footer extends React.Component{
    render(){
      const contactData=jsonData.contact;     
      let serviceIndex=1;
        return   <footer id="footer">
{/* 
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h4>Join Our Newsletter</h4>
                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                <form action="" method="post">
                  <input type="email" name="email"/><input type="submit" value="Subscribe"/>
                </form>
              </div>
            </div>
          </div>
        </div> */}
    
        <div className="footer-top">
          <div className="container">
            <div className="row">
    
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>{contactData.title}</h3>
                <p>
                {contactData.address} <br/>
                  <strong>Phone:</strong>{contactData.phone.map((d,i)=>{
                    return <span>{d},</span>
                  })}<br/>
                  <strong>Email:</strong> {contactData.email.map((d,i)=>{
                    return <span>{d},</span>
                  })}<br/>
                </p>
              </div>
    
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  {jsonData.header.nav.map((d,i)=>{
                    if(d.id.match('service')){
                      serviceIndex=i;
                    }
                    return  <li><i className="bx bx-chevron-right"></i> <a href={d.link}>{d.title}</a></li>
                  })}
                 
                  {/* <li><i className="bx bx-chevron-right"></i> <a href="/">About us</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="/">Services</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="/">Terms of service</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="/">Privacy policy</a></li> */}
                </ul>
              </div>
    
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                {jsonData.header.nav[serviceIndex].subChildren.map((d,i)=>{
                  return   <li><i className="bx bx-chevron-right"></i> <a href={d.link}>{d.title}</a></li>
                })}
                
                  {/* <li><i className="bx bx-chevron-right"></i> <a href="/">Web Development</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="/">Product Management</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="/">Marketing</a></li>
                  <li><i className="bx bx-chevron-right"></i> <a href="/">Graphic Design</a></li> */}
                </ul>
              </div>
    
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                {/* <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p> */}
                <div className="social-links mt-3">
                  <a href={contactData.twitter} className="twitter" aria-label="twitter"><i className="bx bxl-twitter"></i></a>
                  <a href={contactData.facebook} className="facebook" aria-label="facebook"><i className="bx bxl-facebook"></i></a>
                  <a href={contactData.instagram} className="instagram" aria-label="instagram"><i className="bx bxl-instagram"></i></a>
                  <a href={contactData.justdial} className="google-plus" aria-label="google-plus"><img style={{width:'25px'}} src='/assets/justdial.png' alt="justdial"/></a>
                  <a href={contactData.whatsapp} className="whatsapp" aria-label="whatsapp"><i className="bx bxl-whatsapp"></i></a>
                </div>
              </div>
    
            </div>
          </div>
        </div>
    
        <div className="container footer-bottom clearfix">
          <div className="copyright">
            &copy; Copyright <strong><span>{contactData.title}</span></strong>. All Rights Reserved
          </div>
          
        </div>
      </footer>;
    }
}

export default Footer;