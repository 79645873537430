import { API_BASE_URL } from "./Constants";

async function getData(appendUrl,body){   
       
           return fetch(`${API_BASE_URL+appendUrl}`)
    }
    async function getServiceImages(dd){
        console.log("ddfdf "+dd);
        return await getData(`image/${dd}`);
    }
    async function getServiceImagesWithPagination(dd,index){
      console.log("ddfdf "+dd);
      return await getData(`image/gallery/${dd}?page=${index}`);
  }
export  {
  getServiceImages,getServiceImagesWithPagination
};