import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const script = document.createElement('script');
script.src = "assets/js/main.js";
script.async = true;
document.body.appendChild(script);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <App />
 
);

reportWebVitals();
