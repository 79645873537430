import React from "react";
import { useParams } from "react-router-dom";
import BreadCrumbs from "./Breadcrumbs";
import servicesData from './data/services_data.json'
import {getServiceImages} from './DatabaseHelper'
import { IMAGE_URL } from "./Constants";
import { Helmet } from "react-helmet";
function ServiceDetails(Component) {
  console.log("bhjgjhg")
  return (<PortfolioDetails params={useParams()} />);

}
class PortfolioDetails extends React.Component {
  constructor() {
    super();
  
    this.state = { service: null, images: [], loaded: false ,serviceID:null}
  }
  componentDidMount() {
    const serviceID = this.props.params.id.replace("-work",'');

    this.setState({
      serviceID:serviceID,
      service: servicesData[serviceID]
    });
   this.getData(serviceID);
   
   
  }
  async getData(dd){
    await getServiceImages(dd).then(async (t)=>{
      this.setState({
      images: (await t.json()).images
    })
    console.log("getServiceImages "+JSON.stringify(this.state))
    })
   
    // this.setState({
    //   images: dd11
    // })
    
  }
  render() {
    return (
      this.state.service ?
        <div>
          <Helmet>          
          <title>{this.state.service.title} Works in nagercoil kanniyakumari marthandam</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="description" content={this.state.service.fulltext}/>
        </Helmet>          
          <BreadCrumbs data={this.state.service} id={this.state.serviceID}></BreadCrumbs>
          <h2 class="hide">Best and top {this.state.service.title} workers in nagercoil kanniyakumari marthandam</h2>
          <section id="portfolio-details" className="portfolio-details">
            <div className="container">
              <div className="row gy-4 content">
                <div className="col-lg-6">
                  {
                    <div className="portfolio-details-slider swiper">
                      <div className="swiper-wrapper align-items-center">
                        {this.state.images.map((d, i) => {
                          return <div className="swiper-slide">
                            <img src={IMAGE_URL+d.image_path} alt={`${i}`} />
                          </div>
                        })}
                      </div>
                      <div className="swiper-pagination"></div>
                    </div>
                  }
                </div>

                <div className="col-lg-6">
                  {/* <div className="portfolio-info">
                <h3>Project information</h3>
                <ul>
                  <li><strong>Category</strong>: Web design</li>
                  <li><strong>Client</strong>: ASU Company</li>
                  <li><strong>Project date</strong>: 01 March, 2020</li>
                  <li><strong>Project URL</strong>: <a href="#">www.example.com</a></li>
                </ul>
              </div> */}
                  <div className="portfolio-description">
                    <h2>{this.state.service.title}</h2>
                    <p className="text-justify">
                      {this.state.service.fulltext}
                    </p>
                    <ul type="none">
                      {this.state.service.points.map((d, i) => {
                        return <li><i className="ri-check-double-line"></i> {d}</li>

                      })}

                    </ul>
                    
                  </div>
                  <a className="btn btn-primary" href={`/service/gallery/${this.state.serviceID}`}>Show More Images</a>
                </div>

              </div>

            </div>
          </section>

        </div> : <div>sh</div>)

  }
}
export default ServiceDetails;