import React, { useState } from "react";
class ContactUs extends React.Component{
  constructor(){
    super();
   this.state={}
  }
componentDidMount(){
 
}

    render(){
      const contactDetails =this.props.data;
      console.log(contactDetails);
        return  <section id="contact_us" className="contact">
        <div className="container" data-aos="fade-up">
  
          <div className="section-title">
            <h2>Contact</h2>
            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
          </div>
  
          <div className="row">
  
            <div className="col-lg-5 d-flex align-items-stretch">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>{contactDetails.address}</p>
                </div>
  
                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                 { contactDetails.email.map((d,i)=>{
return  <p>{d}</p>
                  })}
                 
                </div>
  
                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Call:</h4>
                  { contactDetails.phone.map((d,i)=>{
return  <p>{d}</p>
                  })}
                 
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3949.0815068909187!2d77.42067417384025!3d8.19454435149483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f0d6c3ed0da9%3A0xf2c19fb0833ea1f1!2sSHREE%20PRAJNAAS%20ENGINEERING!5e0!3m2!1sen!2sin!4v1687857785104!5m2!1sen!2sin"  
                style={{border:"0", width: "100%", height: "290px"}} ></iframe>
              </div>
  
            </div>
  
            <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
              <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label >Your Name</label>
                    <input type="text" name="name" className="form-control" id="name" required/>
                  </div>
                  <div className="form-group col-md-6">
                    <label >Your Email</label>
                    <input type="email" className="form-control" name="email" id="email" required/>
                  </div>
                </div>
                <div className="form-group">
                  <label >Subject</label>
                  <input type="text" className="form-control" name="subject" id="subject" required/>
                </div>
                <div className="form-group">
                  <label >Message</label>
                  <textarea className="form-control" name="message" rows="10" required></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
              </form>
            </div>
  
          </div>
  
        </div>
      </section>;
    }
}
export default ContactUs;