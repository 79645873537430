import React from "react";

class Header extends React.Component{
  insertChildLevelNav(d,drop){
    if(d.subChildren && d.subChildren.length>0)
    {
      // if(d.id=="service" && this.props.servicesList.length>0){
      //   return <li className="dropdown"><a href="/"><span>{d.title}</span> <i className="bi bi-chevron-down"></i></a>
      //   <ul>
      //     {this.props.servicesList.map((d1,i1)=>{
      //       return this.insertChildLevelNav(d1)
      //     })}
      //     </ul>
      //     </li>
      // }
      // else{
        return <li className="dropdown"><a href="/"><span>{d.title}</span> <i className="bi bi-chevron-down"></i></a>
        <ul>
          {d.subChildren.map((d1,i1)=>{
            return this.insertChildLevelNav(d1,"drop")
          })}
          </ul>
          </li>
     // }

    }
    else{
   
      if(drop==="drop" ){
        return <li><a className="nav-link " href={`/service/${d.id!==undefined?d.id:d.service_id}`}>{d.title}</a></li>

      }else{
  return <li><a className="nav-link " href={`#${d.id!==undefined?d.id:d.service_id}`}>{d.title}</a></li>
    
      }}
  }
   render(){
    return   <header id="header" className="fixed-top ">
    <div className="container d-flex align-items-center">

      <h1 className="logo me-auto"><a href="/"><img src="/logo.png" alt="logo"/>KV Builders</a></h1>
     


      <nav id="navbar" className="navbar">
        <ul>
          {this.props.data.map((d,i)=>{
         
          return this.insertChildLevelNav(d,i)
          })}
          
          {/* <li><a className="nav-link scrollto" href="#about">About</a></li>
          <li className="dropdown"><a href="#"><span>Services</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Services</a></li>             
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li>
          <li><a className="nav-link scrollto" href="#services">Services</a></li>
          <li><a className="nav-link   scrollto" href="#portfolio">Portfolio</a></li>
          <li><a className="nav-link scrollto" href="#team">Team</a></li>
          <li className="dropdown"><a href="#"><span>Drop Down</span> <i className="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li>
          <li><a className="nav-link scrollto" href="#contact">Contact</a></li> */}
         
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>
   } 
}
export default Header;