import React from "react";
import{callToNumber} from './global_data'
class Hero extends React.Component{
  
  componentDidMount(){
    console.log("Hero ",this.props);
  }
    render(){
        return  <section id="hero" className="d-flex align-items-center ">

        <div className="container  ">
       
          <div className="row ">
             
          <div className="col-lg-6 hero-img " data-aos="zoom-in" data-aos-delay="200">
              <img src="/logo.png" className=" animated logo-size" alt="logo"/>
            </div>
            
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 text-white" data-aos="fade-up" data-aos-delay="200"  >
          
         
              <h3>{this.props.data.tagline}</h3>
              <h4>{this.props.data.miniDescription}</h4>
              
              <div className="d-flex justify-content-center justify-content-lg-start">
                <div onClick={()=>{
               callToNumber(this.props.data.phone)
                }}  className="btn btn-primary " >Call Now</div>
                {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
              </div>
              </div>
         
          </div>
        </div>
    
      </section>
    }
}
export default Hero;