import { Gallery } from "react-grid-gallery";

import BreadCrumbs from "./Breadcrumbs";
import servicesData from './data/services_data.json'
import { useParams } from "react-router-dom";
import { getServiceImages, getServiceImagesWithPagination } from "./DatabaseHelper";
import { IMAGE_HD_URL, IMAGE_URL } from "./Constants";

import React, { useState, useCallback } from 'react';

import ImageViewer from 'react-simple-image-viewer';
function ServiceGaleryDetails(Component) {
    console.log("bhjgjhg")
    return (<ServiceGallery params={useParams()} />);

}

class ServiceGallery extends React.Component {
    constructor() {
        super();
        this.state = { service: null, images: [],imageUrlList:[], loaded: false,serviceID:"", isViewerOpen: false, selectedIndex: 0,pageIndex:1,dataEnd:false }
    }
    componentDidMount() {
        const dd = this.props.params.id;
        this.setState({
            serviceID:dd,
            service: servicesData[dd]
        });
        this.getData(dd);
    }
    async getData(dd) {
        await getServiceImagesWithPagination(dd,this.state.pageIndex).then(async (t) => {
            
               var dddd=  (await t.json())
           
            var tempImageList=[];
            tempImageList= dddd.data.map((s,i)=>{ 
                this.state.images.push(s)
                this.state.imageUrlList.push(IMAGE_HD_URL+s.image_path);
                return s;
            })
           this.state.pageIndex=dddd.current_page+1;
            if((dddd.total/dddd.per_page)<=dddd.current_page){
                this.state.dataEnd=true
                
            }
            this.setState({ })
            console.log("getServiceImages " + JSON.stringify(this.state))
        })
    }
    openImageViewer(index) {
        this.setState({
            isViewerOpen: true, selectedIndex: index
        });
    }
    closeImageViewer(d) {
        d.setState({
            isViewerOpen: false, selectedIndex: 0
        });
    }
    render() {
        return ( this.state.service ?<div>
          
        <div><BreadCrumbs data={this.state.service}></BreadCrumbs>
        
            <section id="portfolio-details" className="portfolio-details">
                <div className="container">

                    <div className="lightbox">
                   
                        <div className="row justify-content-center">
                            {
                                this.state.images.map((img, i) => {
                                    return <div class="col-12 col-lg-4">
                                        <div className="card">
                                        <img
                                            src={IMAGE_URL+img.image_path}
                                            data-mdb-img={img.image_path}
                                            alt={i}
                                            width="300"
                                            key={ i }
                                            style={{ margin: '2px' }}                                           
                                            onClick={() => this.openImageViewer(i)}
                                            className="w-100 shadow-1-strong rounded card-img-top"
                                        />
                                        <h5 class="card-title text-light hide">
                                        {img.description}
					</h5>
                                       
                                         </div>
                                    </div>
                                })
                            }


                        </div>
                        {this.state.dataEnd?<div></div>:<div className="w-100 btn btn-outline-dark" onClick={()=>this.getData(this.state.serviceID)}>More</div>}
                    </div>
                </div>
                {this.state.isViewerOpen && (
                    <div className="bring-front">
                <ImageViewer
                
                    src={this.state.imageUrlList}
                    currentIndex={this.state.selectedIndex}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={()=>this.closeImageViewer(this)}
                    
                /></div>
            )}
            </section>
           
        </div>
      
        </div>:<div></div>
    )}
}
export {ServiceGaleryDetails};
