const callToNumber=(a)=>{
  console.log("dfsf ",a)
    window.location ='tel:+91'+a;
  }
  const mailToId=(a)=>{
    window.location ('mailto:'+a,"_self")
  }

  export {
    callToNumber,
    mailToId,
  }