import React from "react";

class About extends React.Component{
  
    render(){
      const about=this.props.data;
        return  <section id="about" className="about">
        <div className="container" data-aos="fade-up">
  
          <div className="section-title">
            <h2>About Us</h2>
          </div>
  
          <div className="row content ">
            <div className="col-lg-3 text-center" data-aos="zoom-in" data-aos-delay="200">
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua.
              </p>
              <ul>
                <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
                <li><i className="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
                <li><i className="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat</li>
              </ul> */}
              
              <img src="/logocolo.png" className=" animated " alt="logo"/>
            
            </div>
            <div className="col-lg pt-4 pt-lg-0">
              <p className="text-justify">
              {about.paragraph}
              </p>
              <ul>
                {about.points.map((d,i)=>{
                  return   <li><i className="ri-check-double-line"></i> {d}</li>

                })}
               
              </ul>
              {/* <a href="#" className="btn-learn-more">Learn More</a> */}
            </div>
          </div>
  
        </div>
      </section>;
    }
}
export default About;