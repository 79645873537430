import React from "react";
class BreadCrumbs extends React.Component{
  constructor(){
    super();
    
  }
 
    render(){
        return    <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
  
          <ol>
            <li><a href="/">Home</a></li>
            <li>{this.props.data.title}</li>
           

          </ol>
          <h2>{this.props.data.title}</h2>
  
        </div>
      </section>
    }
}
export default BreadCrumbs;