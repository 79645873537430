
import Header from './Header';
import Hero from './Hero';
import About from './About';
import WhyUs from './WhyUs';
import ContactUs from './Contact';
import Footer from './Footer';
import React, { useState } from 'react';
import jsonData from './data/data.json'
import { BrowserRouter, Routes, Route, NavLink,Router, Await } from "react-router-dom";
import ServiceDetails from './PortfolioDetails';
import {ServiceGaleryDetails} from './GalleryService';
import { Helmet } from "react-helmet";
class App extends React.Component {
  constructor(){
    super();
    this.state={"services":[]}
  }
  componentDidMount(){
  // this.getData();

  }
  render(){
  return <div className="App">       
        
        <Header data={jsonData.header.nav} ></Header>

    <BrowserRouter>

      <Routes>
      <Route path='/' element={
      <div>
        <Hero data={jsonData.header.hero}/>
      <About data={jsonData.about}/>
      <WhyUs data={jsonData.question}/>
      <ContactUs data={jsonData.contact}/>
      </div>}>

      </Route>
      <Route path="/service/:id" element={<ServiceDetails></ServiceDetails>} >
       
    
      </Route>
      <Route path="/service/gallery/:id" element={<ServiceGaleryDetails></ServiceGaleryDetails>}/>
      </Routes>
     </BrowserRouter>
   
      <Footer/>
      
    </div>;
  
}
  getData(){
  try{
    fetch('http://localhost:8000/api/service').then((d)=>{
      d.json().then(d => {
        this.setState({
          "services":d
        })
            console.log(this.state)
        }).catch(e=>{
    
        })
    }).catch((e)=>{

    })
   
  }
  catch(e){

  }
 }
}

export default App;
